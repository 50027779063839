import { useMemo } from 'react';

import type { CartesianPose } from '@sb/geometry';
import { applyCompoundPose, invertPose } from '@sb/geometry';
import type { KinematicState, RoutineRunnerState } from '@sb/routine-runner';
import { useRobotState } from '@sbrc/hooks';
import {
  arePosesEqual,
  convertEulerPose,
  convertJointAngles,
} from '@sbrc/utils';

import type {
  ControlModeState,
  TargetJointAnglesState,
  TargetPoseState,
} from './shared';

function getInvertedTCPTransform(
  state: RoutineRunnerState | null,
): CartesianPose | null {
  return state
    ? applyCompoundPose(
        state.kinematicState.wristPose,
        invertPose(state.kinematicState.tooltipPoint),
      )
    : null;
}

export function useGhostRobotState(
  controlMode: ControlModeState,
  isVizbot: boolean,
  targetJointAnglesDegrees: TargetJointAnglesState,
  targetPose: TargetPoseState,
): Partial<KinematicState> | null {
  const invertedTCPTransform = useRobotState(
    { isVizbot },
    getInvertedTCPTransform,
    arePosesEqual,
  );

  const jointAnglesDegrees = isVizbot
    ? targetJointAnglesDegrees.vizbot
    : targetJointAnglesDegrees.liveRobot;

  const pose = isVizbot ? targetPose.vizbot : targetPose.liveRobot;

  return useMemo(() => {
    if (controlMode === 'jointControlDual' || controlMode === 'space') {
      if (jointAnglesDegrees) {
        return {
          jointAngles: convertJointAngles.fromDegrees(jointAnglesDegrees),
        };
      }
    }

    if (controlMode === 'toolControlTarget') {
      if (pose && invertedTCPTransform) {
        const targetTCPPose = convertEulerPose.toCartesian(pose);

        const wristPose = applyCompoundPose(
          invertedTCPTransform,
          targetTCPPose,
        );

        return { wristPose };
      }
    }

    return null;
  }, [controlMode, jointAnglesDegrees, pose, invertedTCPTransform]);
}
