import * as zod from 'zod';

export const AlohaMessageOut = zod.discriminatedUnion('kind', [
  zod.object({ kind: zod.literal('move_to_start') }),
  zod.object({ kind: zod.literal('move_to_sync') }),
  zod.object({ kind: zod.literal('move_to_rest') }),
  zod.object({ kind: zod.literal('wait_for_teleop') }),
  zod.object({ kind: zod.literal('freeze') }),
  zod.object({ kind: zod.literal('sync_state') }),
]);

export type AlohaMessageOut = zod.infer<typeof AlohaMessageOut>;

export const AlohaOpMode = zod.union([
  zod.literal('move_completed'),
  zod.literal('wait_for_teleop'),
  zod.literal('teleop'),
  zod.literal('frozen'),
]);

export type AlohaOpMode = zod.infer<typeof AlohaOpMode>;

export const AlohaMessageIn = zod.discriminatedUnion('kind', [
  zod.object({
    kind: zod.literal('state'),
    state: AlohaOpMode,
  }),
  zod.object({
    kind: zod.literal('sync_state'),
    in_sync_state: zod.boolean(),
  }),
  zod.object({
    kind: zod.literal('message'),
    message: zod.string(),
  }),
  zod.object({
    kind: zod.literal('error'),
    message: zod.string(),
  }),
]);

export type AlohaMessageIn = zod.infer<typeof AlohaMessageIn>;

export interface AlohaState {
  isConnected: boolean;
  opMode: AlohaOpMode | null;
  isSynced: boolean;
}
