import cx from 'classnames';
import { useState } from 'react';

import type { ComponentColor, IconKind } from '@sb/design-system';
import { HoldableButton, Icon, SegmentedButton } from '@sb/design-system';
import type { CartesianDirection, CartesianPose } from '@sb/geometry';
import {
  useFeatureFlag,
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotStateKind,
  useRobotTooltipState,
  useRobotValidToolDirections,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';

import { SelectActiveTCPOffsetOption } from '../../../tcp-offset/SelectActiveTCPOffsetOption';
import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';

type DegreeOfFreedom = 'x' | 'y' | 'z' | 'roll' | 'pitch' | 'yaw';
type Direction = '+' | '-';

interface DofInfo {
  '-': {
    offset: CartesianPose;
    label: string;
    ariaLabel: string;
    iconKind: IconKind;
    innerClassName: string;
    outerClassName: string;
  };
  '+': {
    offset: CartesianPose;
    label: string;
    ariaLabel: string;
    iconKind: IconKind;
    innerClassName: string;
    outerClassName: string;
  };
  // When jogging the tooltip, we present a rotated set of axes such that Y is up and Z is forward
  baseDOF: DegreeOfFreedom;
  buttonColor: ComponentColor;
  iconClassName?: string;
}

// 1 / Sqrt(2)
const { SQRT1_2 } = Math;

const DOF_INFO: Record<DegreeOfFreedom, DofInfo> = {
  x: {
    '-': {
      offset: { x: -1, y: 0, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'X -',
      ariaLabel: 'x-negative-arrow',
      iconKind: 'arrowDownFill',
      innerClassName: 'tw-flex-col-reverse tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-6 tw-self-start',
    },
    '+': {
      offset: { x: 1, y: 0, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'X +',
      ariaLabel: 'x-positive-arrow',
      iconKind: 'arrowUpFill',
      innerClassName: 'tw-flex-col tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-4 tw-self-end',
    },
    baseDOF: 'y',
    buttonColor: 'Red',
  },
  y: {
    '-': {
      offset: { x: 0, y: -1, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'Y -',
      ariaLabel: 'y-negative-arrow',
      iconKind: 'arrowRightFill',
      innerClassName: 'tw-flex-row-reverse tw-gap-20',
      outerClassName: 'tw-col-start-4 tw-row-start-5 tw-justify-self-start',
    },
    '+': {
      offset: { x: 0, y: 1, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'Y +',
      ariaLabel: 'y-positive-arrow',
      iconKind: 'arrowLeftFill',
      innerClassName: 'tw-flex-row tw-gap-20',
      outerClassName: 'tw-col-start-2 tw-row-start-5 tw-justify-self-end',
    },
    baseDOF: 'z',
    buttonColor: 'Green',
  },
  z: {
    '-': {
      offset: { x: 0, y: 0, z: -1, i: 0, j: 0, k: 0, w: 1 },
      label: 'Z -',
      ariaLabel: 'z-negative-arrow',
      iconKind: 'arrowDownFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-1 tw-row-start-3 tw-row-end-5',
    },
    '+': {
      offset: { x: 0, y: 0, z: 1, i: 0, j: 0, k: 0, w: 1 },
      label: 'Z +',
      ariaLabel: 'z-positive-arrow',
      iconKind: 'arrowUpFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-5 tw-row-start-3 tw-row-end-5',
    },
    baseDOF: 'x',
    buttonColor: 'Blue',
    iconClassName:
      'tw-w-60 tw-h-60 tw-p-12 tw-rounded-full tw-surface-elevated tw-shadow-30 tw-text-[inherit]',
  },
  roll: {
    '-': {
      offset: { x: 0, y: 0, z: 0, i: -SQRT1_2, j: 0, k: 0, w: SQRT1_2 },
      label: 'Roll -',
      ariaLabel: 'Rx-negative-arrow',
      iconKind: 'arrowUturnDownLeftFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-1 tw-row-start-1 tw-self-start',
    },
    '+': {
      offset: { x: 0, y: 0, z: 0, i: SQRT1_2, j: 0, k: 0, w: SQRT1_2 },
      label: 'Roll +',
      ariaLabel: 'Rx-positive-arrow',
      iconKind: 'arrowUturnDownRightFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-5 tw-row-start-1 tw-self-start',
    },
    baseDOF: 'pitch',
    buttonColor: 'Red',
    iconClassName:
      'tw-w-60 tw-h-60 tw-p-12 tw-rounded-full tw-surface-elevated tw-shadow-30 tw-text-[inherit]',
  },
  pitch: {
    '-': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: -SQRT1_2, k: 0, w: SQRT1_2 },
      label: 'Pitch -',
      ariaLabel: 'Ry-negative-arrow',
      iconKind: 'arrowDownFill',
      innerClassName: 'tw-flex-col-reverse tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-3 tw-self-start',
    },
    '+': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: SQRT1_2, k: 0, w: SQRT1_2 },
      label: 'Pitch +',
      ariaLabel: 'Ry-positive-arrow',
      iconKind: 'arrowUpFill',
      innerClassName: 'tw-flex-col tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-1 tw-self-end',
    },
    baseDOF: 'yaw',
    buttonColor: 'Green',
  },
  yaw: {
    '-': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: 0, k: -SQRT1_2, w: SQRT1_2 },
      label: 'Yaw -',
      ariaLabel: 'Rz-negative-arrow',
      iconKind: 'arrowRightFill',
      innerClassName: 'tw-flex-row-reverse tw-gap-20',
      outerClassName: 'tw-col-start-4 tw-row-start-2 tw-justify-self-start',
    },
    '+': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: 0, k: SQRT1_2, w: SQRT1_2 },
      label: 'Yaw +',
      ariaLabel: 'Rz-positive-arrow',
      iconKind: 'arrowLeftFill',
      innerClassName: 'tw-flex-row tw-gap-20',
      outerClassName: 'tw-col-start-2 tw-row-start-2 tw-justify-self-end',
    },
    baseDOF: 'roll',
    buttonColor: 'Blue',
  },
};

export function ToolControl() {
  const { frameOfReference, isVizbot, robot, setFrameOfReference } =
    useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { stopGuidedMode, runAdHocCommand } = useGuidedMode({ isVizbot });

  const [isAdHocCommandRunning, setIsAdHocCommandRunning] =
    useState<boolean>(false);

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const routineRunnerStateKind = useRobotStateKind({ isVizbot });

  const validToolDirections = useRobotValidToolDirections({ isVizbot });

  const tooltipPose = useRobotTooltipState({ isVizbot });

  const [currentlyClickedDOF, setCurrentlyClickedDOF] =
    useState<DegreeOfFreedom | null>(null);

  const [currentlyClickedDirection, setCurrentlyClickedDirection] =
    useState<Direction | null>(null);

  const handleToolPoseCompleted = () => {
    setCurrentlyClickedDOF(null);
    setCurrentlyClickedDirection(null);
  };

  const isToolPoseDisabled = (
    dof: DegreeOfFreedom,
    direction: Direction,
  ): boolean => {
    if (
      // user should not interact with tool control if kinematic state hasn't loaded
      !tooltipPose ||
      // or if the robot is running a routine
      (routineRunnerStateKind === 'RoutineRunning' && !isVizbot) ||
      // or if another session is running an adhoc command
      (isAnotherSessionMovingRobot && !isVizbot) ||
      // or if an adhoc command hasn't completed yet, but the button has been released
      // (i.e. prevent sending too many commands at once)
      (isAdHocCommandRunning && !currentlyClickedDOF)
    ) {
      return true;
    }

    // while a button is held, disable all other buttons
    if (!validToolDirections) {
      return !(
        currentlyClickedDOF === dof && currentlyClickedDirection === direction
      );
    }

    const mappedDof =
      frameOfReference === 'tooltip' ? DOF_INFO[dof].baseDOF : dof;

    const cartesianDirection: CartesianDirection = `${direction}${mappedDof}`;

    // when no buttons held, disable invalid buttons
    return !validToolDirections[frameOfReference][cartesianDirection];
  };

  const onToolPoseChange = async (
    dof: DegreeOfFreedom,
    direction: Direction,
  ) => {
    if (isToolPoseDisabled(dof, direction)) {
      return;
    }

    const handleToolPoseChange = async (): Promise<void> => {
      const speedProfile = await getAdHocSpeedProfile(
        robot.id,
        isVizbot,
        isAdHocFullSpeed,
      );

      const mappedDof =
        frameOfReference === 'tooltip' ? DOF_INFO[dof].baseDOF : dof;

      const offsetPose = DOF_INFO[mappedDof][direction].offset;

      return routineRunnerHandle.moveToolRelative(
        frameOfReference,
        offsetPose,
        speedProfile,
      );
    };

    setIsAdHocCommandRunning(true);

    await runAdHocCommand({
      onRunCommand: handleToolPoseChange,
      onComplete: handleToolPoseCompleted,
    });

    setIsAdHocCommandRunning(false);
  };

  const startJogging = (dof: DegreeOfFreedom, direction: Direction): void => {
    if (!isToolPoseDisabled(dof, direction)) {
      setCurrentlyClickedDOF(dof);
      setCurrentlyClickedDirection(direction);
      onToolPoseChange(dof, direction);
    }
  };

  const stopJogging = () => {
    setCurrentlyClickedDOF(null);
    setCurrentlyClickedDirection(null);
    stopGuidedMode();
  };

  const getButton = (dof: DegreeOfFreedom, direction: Direction) => {
    const dofInfo = DOF_INFO[dof];
    const dofDirInfo = dofInfo[direction];

    return (
      <HoldableButton
        color={dofInfo.buttonColor}
        variant="Flat"
        className={cx(
          'tw-h-auto',
          'tw-p-0',
          'tw-rounded-0',
          dofDirInfo.outerClassName,
        )}
        aria-label={dofDirInfo.ariaLabel}
        onHold={() => startJogging(dof, direction)}
        onRelease={stopJogging}
        disabled={isToolPoseDisabled(dof, direction)}
      >
        <div
          className={cx(
            'tw-flex',
            'tw-items-center',
            dofDirInfo.innerClassName,
          )}
        >
          <span className={cx('tw-text-13', 'tw-text-label-secondary')}>
            {dofDirInfo.label}
          </span>
          <Icon
            kind={dofDirInfo.iconKind}
            className={dofInfo.iconClassName ?? 'tw-w-36'}
          />
        </div>
      </HoldableButton>
    );
  };

  return (
    <>
      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-grid',
          'tw-grid-cols-[60px_1fr_40px_1fr_60px]',
          'tw-grid-rows-[100px_40px_84px_84px_40px_84px]',
          'tw-items-center',
          'tw-justify-items-center',
          'tw-pt-10',
          'tw-px-16',
        )}
      >
        <div
          className={cx(
            'tw-surface-elevated',
            'tw-shadow-30',
            'tw-w-[136px]',
            'tw-h-[136px]',
            'tw-rounded-full',
            'tw-col-start-3',
            'tw-row-start-2',
          )}
        />
        <div
          className={cx(
            'tw-h-12',
            'tw-w-12',
            'tw-rounded-full',
            'tw-bg-label-tertiary',
            'tw-col-start-3',
            'tw-row-start-2',
          )}
        />

        <div
          className={cx(
            'tw-surface-elevated',
            'tw-shadow-30',
            'tw-w-[136px]',
            'tw-h-[136px]',
            'tw-rounded-full',
            'tw-col-start-3',
            'tw-row-start-5',
          )}
        />
        <div
          className={cx(
            'tw-h-12',
            'tw-w-12',
            'tw-rounded-full',
            'tw-bg-label-tertiary',
            'tw-col-start-3',
            'tw-row-start-5',
          )}
        />

        {getButton('x', '+')}
        {getButton('x', '-')}
        {getButton('y', '+')}
        {getButton('y', '-')}
        {getButton('z', '+')}
        {getButton('z', '-')}
        {getButton('roll', '+')}
        {getButton('roll', '-')}
        {getButton('pitch', '+')}
        {getButton('pitch', '-')}
        {getButton('yaw', '+')}
        {getButton('yaw', '-')}
      </div>

      <hr className={cx('tw-border-divider-primary')} />

      <div className={cx('tw-flex', 'tw-m-16')}>
        <SegmentedButton
          checked={frameOfReference === 'tooltip'}
          onChange={() => setFrameOfReference('tooltip')}
          className={cx('tw-flex-1', '[&>button]:tw-rounded-6')}
        >
          Align with tool
        </SegmentedButton>
        <SegmentedButton
          checked={frameOfReference === 'robotArm'}
          onChange={() => setFrameOfReference('robotArm')}
          className={cx('tw-flex-1', '[&>button]:tw-rounded-6')}
        >
          Align with base
        </SegmentedButton>
      </div>

      <SelectActiveTCPOffsetOption
        className="tw-mx-16 tw-mb-16"
        isVizbot={isVizbot}
      />
    </>
  );
}
