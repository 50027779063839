import { convertTCPOffsetOption } from './convertTCPOffsetOption';
import { useActiveTCPOffsetOption } from './useActiveTCPOffsetOption';
import { useTCPOffsetOptions } from './useTCPOffsetOptions';

interface ActiveTCPOffsetOptionProps {
  className?: string;
  isVizbot: boolean;
}

export function ActiveTCPOffsetOption({
  className,
  isVizbot,
}: ActiveTCPOffsetOptionProps) {
  const tcpOffsetOption = useActiveTCPOffsetOption({ isVizbot });
  const offsetOptions = useTCPOffsetOptions();

  return (
    <div className={className}>
      {offsetOptions[convertTCPOffsetOption(tcpOffsetOption, offsetOptions)]}
    </div>
  );
}
