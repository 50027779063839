import { useMemo } from 'react';

import type { ComponentColor, IconKind } from '@sb/design-system';
import { Badge, Icon } from '@sb/design-system';

import type { AlohaState } from './types';

interface AlohaBadgeProps {
  alohaState: AlohaState;
}

export function AlohaBadge({ alohaState }: AlohaBadgeProps) {
  const [badgeColor, badgeIcon, badgeMessage] = useMemo<
    [ComponentColor, IconKind, string]
  >(() => {
    if (!alohaState.isConnected) {
      return ['Red', 'wifiSlash', 'Disconnected'];
    }

    if (alohaState.isSynced) {
      return ['Green', 'checkmarkCircle', 'Synced'];
    }

    return ['Orange', 'xmarkCircle', 'Not synced'];
  }, [alohaState]);

  return (
    <Badge color={badgeColor}>
      <Icon kind={badgeIcon} />
      <span>{badgeMessage}</span>
    </Badge>
  );
}
