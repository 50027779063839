import { IOWidget } from '../../visualizer-view-shared/io/IOWidget';
import { useMoveRobotViewContext } from '../shared';

const MoveRobotIOWidget = () => {
  const { isVizbot, robot, setControlMode } = useMoveRobotViewContext();

  return (
    <IOWidget
      isVizbot={isVizbot}
      robot={robot}
      onClose={() => setControlMode(null)}
    />
  );
};

export default MoveRobotIOWidget;
