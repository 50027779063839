import cx from 'classnames';
import { useState } from 'react';

import { HoldableButton, SettingsGroupSwitchItem } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import type { MotionKind } from '@sb/motion-planning';
import { EulerPoseSettingsGroup } from '@sbrc/components/euler-pose/EulerPoseSettingsGroup';
import {
  useDistanceUnitInfo,
  useFeatureFlag,
  useGuidedMode,
  useRobotTooltipState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';
import type { EulerPose } from '@sbrc/utils';
import { convertEulerPose } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';

import useTargetEulerPose from './useTargetEulerPose';

const ToolControlTargetMode = () => {
  const { robot, isVizbot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { setToast } = useToast();

  const { targetPose, setTargetEulerPose, resetAllTargets } =
    useTargetEulerPose();

  const [motionKind, setMotionKind] = useState<MotionKind>('joint');

  const hasTargets = Boolean(targetPose);

  // Cartesian
  const tooltip: CartesianPose | null = useRobotTooltipState({ isVizbot });

  // Euler
  const tooltipPose: EulerPose =
    convertEulerPose.fromNullableCartesian(tooltip);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const onHoldApplyChanges = () => {
    if (targetPose) {
      const targetCartesianPose = convertEulerPose.toCartesian(targetPose);

      const moveRobotToTargetPosition = async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        return routineRunnerHandle.moveToCartesianSpacePose(
          targetCartesianPose,
          motionKind,
          speedProfile,
        );
      };

      runAdHocCommand({
        onRunCommand: moveRobotToTargetPosition,
        onError(error) {
          setToast({
            kind: 'error',
            message: `Error setting pose target: ${error}`,
          });
        },
        onSuccess() {
          resetAllTargets();
        },
      });
    }
  };

  const distanceUnitInfo = useDistanceUnitInfo();

  return (
    <>
      <div className={cx('tw-flex-1', 'tw-overflow-auto')}>
        <EulerPoseSettingsGroup
          className={cx('tw-mx-16', 'tw-mb-16')}
          distanceUnitInfo={distanceUnitInfo}
          value={targetPose ?? tooltipPose}
          onChange={setTargetEulerPose}
        />

        <SettingsGroupSwitchItem
          isSeparated
          className={cx('tw-mx-16', 'tw-mb-16')}
          label="Move in a straight line"
          checked={motionKind === 'line'}
          onChange={(e) => setMotionKind(e.target.checked ? 'line' : 'joint')}
        />
      </div>

      <hr className={cx('tw-border-divider-primary')} />

      <HoldableButton
        variant="Filled"
        onHold={onHoldApplyChanges}
        onRelease={stopGuidedMode}
        disabled={!hasTargets}
        className={cx('tw-rounded-6', 'tw-m-16')}
        data-testid="move-robot-control-panel-widgets-tool-control-button"
      >
        Hold to apply changes
      </HoldableButton>
    </>
  );
};

export default ToolControlTargetMode;
