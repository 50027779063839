import { Icon, NavigationBar, NavigationBarButton } from '@sb/design-system';

import WidgetView from '../../../visualizer-view-shared/widget-panel/WidgetView';
import { useMoveRobotViewContext } from '../../shared';

import { ToolControl } from './ToolControl';
import ToolControlTargetMode from './ToolControlTargetMode';
import { ToolControlTargetModeMoreMenu } from './ToolControlTargetModeMoreMenu';

export function MoveRobotToolControlWidget() {
  const { controlMode, setControlMode } = useMoveRobotViewContext();

  const isTargetMode = controlMode === 'toolControlTarget';

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={isTargetMode && <ToolControlTargetModeMoreMenu />}
        contentRight={
          <NavigationBarButton
            data-testid="move-roboto-control-widgets-tool-switch-control-mode-button"
            onClick={() =>
              setControlMode(isTargetMode ? 'toolControl' : 'toolControlTarget')
            }
            size={36}
            variant={isTargetMode ? 'Filled' : 'Flat'}
            className="tw-rounded-10"
          >
            <Icon kind="sliderHorizontal" />
          </NavigationBarButton>
        }
      >
        Tooltip
      </NavigationBar>

      {isTargetMode ? <ToolControlTargetMode /> : <ToolControl />}
    </WidgetView>
  );
}
