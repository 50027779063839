import { SettingsGroupFullWidthSelectItem } from '@sb/design-system';
import { useFeatureFlag } from '@sbrc/hooks';

import { convertTCPOffsetOption } from './convertTCPOffsetOption';
import { useTCPOffsetOptions } from './useTCPOffsetOptions';

interface SelectTCPOffsetOptionProps {
  className?: string;
  value: number;
  onChange: (value: number) => void;
}

export function SelectTCPOffsetOption({
  className,
  value,
  onChange,
}: SelectTCPOffsetOptionProps) {
  const isTCPTransformEnabled = useFeatureFlag('tcpTransform');
  const offsetOptions = useTCPOffsetOptions();

  if (!isTCPTransformEnabled) {
    return null;
  }

  return (
    <SettingsGroupFullWidthSelectItem
      value={convertTCPOffsetOption(value, offsetOptions)}
      onChange={(e) => {
        const newValue = convertTCPOffsetOption(e.target.value, offsetOptions);
        onChange(newValue);
      }}
      isSeparated
      className={className}
    >
      {offsetOptions.map((optionName, index) => (
        <option key={optionName} value={index}>
          {optionName}
        </option>
      ))}
    </SettingsGroupFullWidthSelectItem>
  );
}
