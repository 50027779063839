import cx from 'classnames';

import { Gauge, GaugeContainer, HoldableButton, Icon } from '@sb/design-system';
import type { ArmJointVelocities, JointNumber } from '@sb/motion-planning';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';
import {
  useFeatureFlag,
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotJointAnglesDegrees,
  useRobotJointLimitsDegrees,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import { JOINT_NAMES } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';

export function JointControl() {
  const { isVizbot, robot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const jointAnglesDegrees = useRobotJointAnglesDegrees({ isVizbot });

  const jointLimitsDegrees = useRobotJointLimitsDegrees({ isVizbot });

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const onHoldSpinButton = (jointNumber: JointNumber, isPositive: boolean) => {
    const handleJointAngleChange = async (): Promise<void> => {
      const speedProfile = await getAdHocSpeedProfile(
        robot.id,
        isVizbot,
        isAdHocFullSpeed,
      );

      const jointRelativeSpeedProfile: SpeedProfile = {
        ...speedProfile,
        maxJointSpeeds: speedProfile.maxJointSpeeds.map((jointSpeed) => {
          // sign reflects direction
          return jointSpeed * (isPositive ? 1 : -1);
        }) as ArmJointVelocities,
      };

      return routineRunnerHandle.moveJointRelative(
        jointNumber,
        jointRelativeSpeedProfile,
      );
    };

    runAdHocCommand({ onRunCommand: handleJointAngleChange });
  };

  return (
    <>
      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-flex',
          'tw-flex-col',
          'tw-gap-12',
          'tw-pt-16',
          'tw-pb-32',
        )}
      >
        {JOINT_NUMBERS.map((jointNumber) => {
          const jointAngle = jointAnglesDegrees?.[jointNumber];
          const limits = jointLimitsDegrees?.[jointNumber];

          return (
            <div
              key={jointNumber}
              className={cx(
                'tw-flex',
                'tw-mx-16',
                'tw-gap-8',
                'tw-items-center',
              )}
            >
              <span className="tw-w-24">{JOINT_NAMES[jointNumber]}</span>

              <GaugeContainer className="tw-flex-1">
                <HoldableButton
                  disabled={
                    isAnotherSessionMovingRobot ||
                    jointAngle === undefined ||
                    !limits ||
                    jointAngle <= limits.min
                  }
                  className="tw-rounded-6"
                  onHold={() => onHoldSpinButton(jointNumber, false)}
                  onRelease={stopGuidedMode}
                >
                  <Icon kind="minus" />
                </HoldableButton>

                <Gauge
                  value={jointAngle ?? 0}
                  min={limits?.min ?? 0}
                  max={limits?.max ?? 0}
                  valueToString={(v) => `${v.toFixed(1)}`}
                />

                <HoldableButton
                  disabled={
                    isAnotherSessionMovingRobot ||
                    jointAngle === undefined ||
                    !limits ||
                    jointAngle >= limits.max
                  }
                  className="tw-rounded-6"
                  onHold={() => onHoldSpinButton(jointNumber, true)}
                  onRelease={stopGuidedMode}
                >
                  <Icon kind="plus" />
                </HoldableButton>
              </GaugeContainer>
            </div>
          );
        })}
      </div>

      <p
        className={cx(
          'tw-text-13',
          'tw-icon-20',
          'tw-text-label-tertiary',
          'tw-text-center',
          'tw-m-16',
        )}
      >
        Hold <Icon kind="plus" className="tw-inline" /> or{' '}
        <Icon kind="minus" className="tw-inline" /> to move arm
      </p>
    </>
  );
}
