import { clamp } from 'lodash';

export function convertTCPOffsetOption(
  unconvertedValue: number | string,
  offsetOptions: string[],
) {
  const numericValue =
    typeof unconvertedValue === 'number'
      ? unconvertedValue
      : Number.parseInt(unconvertedValue, 10) || 0;

  return clamp(numericValue, 0, offsetOptions.length - 1);
}
