import { useEffect, useState } from 'react';

import { AlohaAPIClient } from './AlohaAPIClient';
import type { AlohaState } from './types';

const NULL_ALOHA_STATE: AlohaState = {
  isConnected: false,
  opMode: null,
  isSynced: false,
};

export function useAlohaAPIClient() {
  const [client, setClient] = useState<AlohaAPIClient | null>(null);
  const [alohaState, setAlohaState] = useState<AlohaState>(NULL_ALOHA_STATE);

  useEffect(() => {
    const newClient = new AlohaAPIClient();

    setClient(newClient);

    const isConnectedSub = newClient.isConnected$.subscribe((isConnected) => {
      setAlohaState((s) => ({ ...s, isConnected }));

      if (isConnected) {
        // subscribe to sync_state messages
        newClient.send({ kind: 'sync_state' });
      }
    });

    const stateSub = newClient.receive$.subscribe((message) => {
      switch (message.kind) {
        case 'state':
          setAlohaState((s) => ({ ...s, opMode: message.state }));
          break;
        case 'sync_state':
          setAlohaState((s) => ({ ...s, isSynced: message.in_sync_state }));
          break;
        default:
          break;
      }
    });

    return () => {
      newClient.destroy();
      isConnectedSub.unsubscribe();
      stateSub.unsubscribe();
    };
  }, []);

  return [client, alohaState] as const;
}
