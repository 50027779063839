import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { makeNamespacedLog } from '@sb/log';
import { wait } from '@sb/utilities';
import { API_ENDPOINT } from '@sbrc/utils';

import type { AlohaMessageOut } from './types';
import { AlohaMessageIn } from './types';

const log = makeNamespacedLog('AlohaAPIClient');

export class AlohaAPIClient {
  private isConnectedSubject = new BehaviorSubject(false);

  private sendSubject = new Subject<AlohaMessageOut>();

  private receiveSubject = new Subject<AlohaMessageIn>();

  public constructor() {
    this.connect();
  }

  private isDestroyed = false;

  private connectionSubscription = new Subscription();

  private connect() {
    log.info('connect', 'Connecting...');

    this.connectionSubscription = new Subscription();

    const ws = new WebSocket(`${API_ENDPOINT}aloha-api`);

    this.connectionSubscription.add(() => {
      log.info('connect.teardown', 'Teardown', { readyState: ws.readyState });

      if (ws.readyState !== ws.CLOSING && ws.readyState !== ws.CLOSED) {
        ws.close(4000, 'Teardown');
      }
    });

    const connectingTimeoutID = setTimeout(() => {
      if (ws.readyState === WebSocket.CONNECTING) {
        ws.close(4001, 'Connecting timeout');
      }
    }, 5_000);

    this.connectionSubscription.add(() => clearTimeout(connectingTimeoutID));

    ws.onopen = () => {
      clearTimeout(connectingTimeoutID);
      this.isConnectedSubject.next(true);
    };

    ws.onmessage = (ev) => {
      try {
        const deserializedData = JSON.parse(ev.data);
        const message = AlohaMessageIn.parse(deserializedData);
        this.receiveSubject.next(message);
      } catch (error) {
        log.error('receive.error', 'Error', { error });

        this.receiveSubject.next({
          kind: 'error',
          message: 'Invalid message received',
        });
      }
    };

    this.connectionSubscription.add(
      this.sendSubject.subscribe((message) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(message));
        }
      }),
    );

    ws.onerror = () => {
      log.warn('error', 'Connection failed');
    };

    ws.onclose = async () => {
      this.isConnectedSubject.next(false);
      this.connectionSubscription.unsubscribe();
      await wait(2000);

      if (!this.isDestroyed) {
        this.connect();
      }
    };
  }

  public readonly receive$ = this.receiveSubject.asObservable();

  public readonly isConnected$ = this.isConnectedSubject.asObservable();

  public send(message: AlohaMessageOut) {
    this.sendSubject.next(message);
  }

  public destroy() {
    log.info('Destroy', 'Destroying...');
    this.connectionSubscription.unsubscribe();
    this.isConnectedSubject.complete();
    this.sendSubject.complete();
    this.receiveSubject.complete();
    this.isDestroyed = true;
  }
}
