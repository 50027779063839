import { useEquipment } from '@sbrc/hooks';

import { getTCPOffsetOptions } from './getTCPOffsetOptions';

export function useTCPOffsetOptions() {
  const equipment = useEquipment();
  const offsetOptions = getTCPOffsetOptions(equipment ?? []);

  return offsetOptions;
}
