import { Button, HoldableButton, useInterval } from '@sb/design-system';

import { AlohaBadge } from './AlohaBadge';
import type { AlohaMessageOut } from './types';
import { useAlohaAPIClient } from './useAlohaAPIClient';

export function AlohaControlPanel() {
  const [client, alohaState] = useAlohaAPIClient();

  const { startInterval, stopInterval } = useInterval(1000);

  const handleHold = (message: AlohaMessageOut) => () => {
    startInterval(() => client?.send(message));
  };

  const handleRelease = () => {
    stopInterval();
    client?.send({ kind: 'freeze' });
  };

  return (
    <div className="tw-flex tw-flex-col tw-overflow-auto tw-gap-8 tw-px-16 tw-pb-16">
      {alohaState.opMode !== 'teleop' && !alohaState.isSynced && (
        <HoldableButton
          className="tw-rounded-10"
          disabled={!alohaState.isConnected}
          onHold={handleHold({ kind: 'move_to_sync' })}
          onRelease={handleRelease}
        >
          Hold to sync arms
        </HoldableButton>
      )}

      {alohaState.opMode !== 'teleop' && alohaState.isSynced && (
        <Button
          className="tw-rounded-10"
          disabled={
            !alohaState.isConnected || alohaState.opMode === 'wait_for_teleop'
          }
          onClick={() => client?.send({ kind: 'wait_for_teleop' })}
        >
          {alohaState.opMode === 'wait_for_teleop'
            ? 'Squeeze gripper to begin'
            : 'Start teleop'}
        </Button>
      )}

      {alohaState.opMode !== 'frozen' && (
        <Button
          className="tw-rounded-10"
          disabled={!alohaState.isConnected}
          onClick={() => client?.send({ kind: 'freeze' })}
        >
          {alohaState.opMode === 'teleop' ? 'Stop teleop' : 'Freeze'}
        </Button>
      )}

      {alohaState.opMode !== 'teleop' && (
        <HoldableButton
          className="tw-rounded-10"
          disabled={!alohaState.isConnected}
          onHold={handleHold({ kind: 'move_to_rest' })}
          onRelease={handleRelease}
        >
          Hold to move to rest position
        </HoldableButton>
      )}

      {alohaState.opMode !== 'teleop' && (
        <HoldableButton
          className="tw-rounded-10"
          disabled={!alohaState.isConnected}
          onHold={handleHold({ kind: 'move_to_start' })}
          onRelease={handleRelease}
        >
          Hold to move to start
        </HoldableButton>
      )}

      <div className="tw-flex tw-justify-between tw-items-end tw-mt-16">
        <span className="tw-text-13">{alohaState.opMode}</span>
        <AlohaBadge alohaState={alohaState} />
      </div>
    </div>
  );
}
