import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { convertEulerPose } from '@sbrc/utils';

import { useMoveRobotViewContext } from '../../shared';

import useTargetEulerPose from './useTargetEulerPose';

export function ToolControlTargetModeMoreMenu() {
  const { isVizbot } = useMoveRobotViewContext();

  const { targetPose, setTargetEulerPose, resetAllTargets } =
    useTargetEulerPose();

  const hasTargets = Boolean(targetPose);

  const otherRobotRoutineRunnerHandle = useRoutineRunnerHandle({
    isVizbot: !isVizbot,
  });

  return (
    <NavigationBarMenuButton
      data-testid="tool-control-more-menu"
      placement="bottom-start"
      content={
        <Menu>
          <MenuItem
            data-testid="tool-control-import-position"
            onClick={() => {
              const otherRobotTooltipPose =
                otherRobotRoutineRunnerHandle.getState()?.kinematicState
                  .tooltipPoint;

              const otherRobotEulerPose =
                convertEulerPose.fromNullableCartesian(otherRobotTooltipPose);

              if (otherRobotEulerPose) {
                setTargetEulerPose(otherRobotEulerPose);
              }
            }}
            iconKind="arrowDownSquare"
          >
            Import {isVizbot ? 'live robot' : 'visualizer'} position
          </MenuItem>

          <MenuItem
            data-testid="tool-control-reset-all"
            onClick={resetAllTargets}
            disabled={!hasTargets}
            iconKind="arrowUturnLeft"
          >
            Reset all
          </MenuItem>
        </Menu>
      }
    />
  );
}
