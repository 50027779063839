import { useRoutineRunnerHandle } from '@sbrc/hooks';

import { SelectTCPOffsetOption } from './SelectTCPOffsetOption';
import { useActiveTCPOffsetOption } from './useActiveTCPOffsetOption';

interface SelectActiveTCPOffsetOptionProps {
  className?: string;
  isVizbot: boolean;
}

export function SelectActiveTCPOffsetOption({
  className,
  isVizbot,
}: SelectActiveTCPOffsetOptionProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const tcpOffsetOption = useActiveTCPOffsetOption({ isVizbot });

  return (
    <SelectTCPOffsetOption
      className={className}
      value={tcpOffsetOption}
      onChange={(value) => routineRunnerHandle.setTCPOffsetOption(value)}
    />
  );
}
