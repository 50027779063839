import { Icon, NavigationBar, NavigationBarButton } from '@sb/design-system';

import WidgetView from '../../../visualizer-view-shared/widget-panel/WidgetView';
import { useMoveRobotViewContext } from '../../shared';

import { JointControl } from './JointControl';
import { JointControlTargetMode } from './JointControlTargetMode';
import { JointControlTargetModeMoreMenu } from './JointControlTargetModeMoreMenu';

export function MoveRobotJointControlWidget() {
  const { controlMode, setControlMode } = useMoveRobotViewContext();

  const isTargetMode = controlMode === 'jointControlDual';

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={isTargetMode && <JointControlTargetModeMoreMenu />}
        contentRight={
          <NavigationBarButton
            onClick={() =>
              setControlMode(
                isTargetMode ? 'jointControlSingle' : 'jointControlDual',
              )
            }
            variant={isTargetMode ? 'Filled' : 'Flat'}
            size={36}
            data-testid="move-robot-control-widgets-tool-switch-control-mode-button"
            className="tw-rounded-10"
          >
            <Icon kind="sliderHorizontal" />
          </NavigationBarButton>
        }
      >
        Joints
      </NavigationBar>

      {isTargetMode ? <JointControlTargetMode /> : <JointControl />}
    </WidgetView>
  );
}
