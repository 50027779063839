import { NavigationBar } from '@sb/design-system';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

import { useMoveRobotViewContext } from '../../shared';

import { AlohaControlPanel } from './AlohaControlPanel';

export function MoveRobotAlohaControlWidget() {
  const { controlMode } = useMoveRobotViewContext();

  if (controlMode !== 'alohaControl') {
    return null;
  }

  return (
    <WidgetView>
      <NavigationBar className="tw-pl-8 tw-pr-8">
        Demonstration control
      </NavigationBar>
      <AlohaControlPanel />
    </WidgetView>
  );
}
