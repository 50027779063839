import type { DeviceConfiguration } from '@sb/integrations/device';
import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Equipment } from '@sb/remote-control/types';

export function getTCPOffsetOptions(
  equipment: Equipment.ConvertedResponse[] | DeviceConfiguration[],
): string[] {
  const options = ['Wrist flange'];

  for (const equipmentItem of equipment) {
    const config =
      'config' in equipmentItem ? equipmentItem.config : equipmentItem;

    const implementation = getEquipmentByKind(config.kind);

    if (implementation.getTCPOffsetOptions) {
      options.push(...implementation.getTCPOffsetOptions(config));
    }
  }

  return options;
}
