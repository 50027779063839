import cx from 'classnames';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

import { GaugeStepperDualValue, HoldableButton } from '@sb/design-system';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import {
  useFeatureFlag,
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotJointAnglesDegrees,
  useRobotJointLimitsDegrees,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import { JOINT_NAMES, convertJointAngles } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';

import useTargetJointAngles from './useTargetJointAngles';

export function JointControlTargetMode() {
  const { isVizbot, robot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const jointAnglesDegrees = useRobotJointAnglesDegrees({ isVizbot });

  const jointLimitsDegrees = useRobotJointLimitsDegrees({ isVizbot });

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    isVizbot,
  });

  const { setTargetAngle, resetAllTargetAngles, targetJointAngles } =
    useTargetJointAngles();

  // clear target angles when they equal the actual joint angles
  // note, no dependency array, this can run on every render
  useEffect(() => {
    if (
      targetJointAngles &&
      // it is valid to use `isEqual` to compare joint angles because they are rounded to 1dp
      isEqual(jointAnglesDegrees, targetJointAngles)
    ) {
      resetAllTargetAngles();
    }
  });

  const onHoldApplyChanges = () => {
    if (targetJointAngles) {
      const moveRobotToTargetPosition = async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        return routineRunnerHandle.moveToJointSpacePoint(
          convertJointAngles.fromDegrees(targetJointAngles),
          speedProfile,
        );
      };

      runAdHocCommand({ onRunCommand: moveRobotToTargetPosition });
    }
  };

  return (
    <>
      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-flex',
          'tw-flex-col',
          'tw-gap-12',
          'tw-py-16',
        )}
      >
        {JOINT_NUMBERS.map((jointNumber) => {
          const jointAngle = jointAnglesDegrees?.[jointNumber];
          const targetAngle = targetJointAngles?.[jointNumber];
          const limits = jointLimitsDegrees?.[jointNumber];

          return (
            <div
              key={jointNumber}
              className={cx(
                'tw-flex',
                'tw-mx-16',
                'tw-gap-8',
                'tw-items-center',
              )}
            >
              <span className="tw-w-24">{JOINT_NAMES[jointNumber]}</span>

              <GaugeStepperDualValue
                className="tw-flex-1"
                value={jointAngle ?? 0}
                targetValue={
                  jointAngle === targetAngle ? undefined : targetAngle
                }
                onChange={setTargetAngle(jointNumber)}
                min={limits?.min ?? 0}
                max={limits?.max ?? 0}
                isDisabled={jointAngle === undefined || !limits}
                step={1}
                valueToString={(v) => `${v.toFixed(1)}`}
              />
            </div>
          );
        })}
      </div>

      <hr className={cx('tw-border-divider-primary')} />

      <HoldableButton
        variant="Filled"
        className={cx('tw-rounded-6', 'tw-m-16')}
        disabled={!targetJointAngles || isAnotherSessionMovingRobot}
        onHold={onHoldApplyChanges}
        onRelease={stopGuidedMode}
        data-testid="move-robot-control-panel-widgets-joint-control-button"
      >
        Hold to apply changes
      </HoldableButton>
    </>
  );
}
