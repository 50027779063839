import type { EulerPose } from '@sbrc/utils';

import { useMoveRobotViewContext } from '../../shared';

export default function useTargetEulerPose() {
  const { isVizbot, setTargetPose, targetPose } = useMoveRobotViewContext();

  const targetKey = isVizbot ? 'vizbot' : 'liveRobot';

  const setTargetEulerPose = (newTarget: EulerPose) => {
    setTargetPose((previousState) => {
      return {
        ...previousState,
        [targetKey]: newTarget,
      };
    });
  };

  const resetAllTargets = () => {
    setTargetPose((previousState) => {
      return {
        ...previousState,
        [targetKey]: null,
      };
    });
  };

  return {
    setTargetEulerPose,
    resetAllTargets,
    targetPose: targetPose[targetKey],
  };
}
