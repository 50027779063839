import WidgetPanel from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetPanel';

import { useMoveRobotViewContext } from '../shared';

import { MoveRobotAlohaControlWidget } from './aloha/MoveRobotAlohaControlWidget';
import { MoveRobotJointControlWidget } from './joints-widget/MoveRobotJointControlWidget';
import { MoveRobotEquipmentWidget } from './MoveRobotEquipmentWidget';
import MoveRobotIOWidget from './MoveRobotIOWidget';
import MoveRobotSpaceWidget from './MoveRobotSpaceWidget';
import { MoveRobotToolControlWidget } from './tool-control-widget/MoveRobotToolControlWidget';

interface ControlPanelWidgetViewProps {
  isHidden: boolean;
}

export function ControlPanelWidgetView({
  isHidden,
}: ControlPanelWidgetViewProps) {
  const { controlMode } = useMoveRobotViewContext();

  return (
    <WidgetPanel position="bottom-right" isHidden={isHidden}>
      {(controlMode === 'toolControl' ||
        controlMode === 'toolControlTarget') && <MoveRobotToolControlWidget />}
      {(controlMode === 'jointControlSingle' ||
        controlMode === 'jointControlDual') && <MoveRobotJointControlWidget />}
      <MoveRobotEquipmentWidget />
      <MoveRobotSpaceWidget />
      {controlMode === 'io' && <MoveRobotIOWidget />}
      <MoveRobotAlohaControlWidget />
    </WidgetPanel>
  );
}
