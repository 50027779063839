import type { RoutineRunnerState } from '@sb/routine-runner';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';
import { useRobotState } from '@sbrc/hooks';

function tcpOffsetOptionSelector(state: RoutineRunnerState | null): number {
  return state?.kinematicState.tcpOffsetOption ?? 0;
}

export function useActiveTCPOffsetOption({
  isVizbot,
}: UseRoutineRunnerHandleArguments) {
  return useRobotState({ isVizbot }, tcpOffsetOptionSelector, Object.is);
}
